const LANGUAGES= [
    {
        locale: 'en',
        label: 'English',
        emoji: '🇺🇸',
        translationKey: 'english'
    },
    {
        locale: 'ro',
        label: 'Română',
        emoji: '🇷🇴',
        translationKey: 'romanian'
    },
    {
        locale: 'ru',
        label: 'Русский',
        emoji: '🇷🇺',
        translationKey: 'russian'
    },
    {
        locale: 'fr',
        label: 'Français',
        emoji: '🇫🇷',
        translationKey: 'french'
    },
    {
        locale: 'de',
        label: 'Deutsch',
        emoji: '🇩🇪',
        translationKey: 'german'
    }
]


export default LANGUAGES;