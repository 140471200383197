
const MEDIAQUERIES = {
    mobileSmallPortrait: `(min-width: 300px) and (orientation: portrait)`,
    mobileLargePortrait: `(min-width: 370px) and (orientation: portrait)`,
    mobileLandscape: `(min-width: 370px) and (orientation: landscape)`,
    tabletSmallPortrait: `(min-width: 768px) and (orientation: portrait)`,
    tabletLargePortrait: `(min-width: 1024px) and (orientation: portrait)`,
    laptopSmall: `(min-width: 1024px) and (orientation: landscape)`,
    laptopLarge: `(min-width: 1500px) and (orientation: landscape)`,
    desktop: `(min-width: 2500px) and (orientation: landscape)`
  };


  export default MEDIAQUERIES;