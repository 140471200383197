const BLACK = "#000"
const WHITE = "#ffffff"
const BORDERCOLOR = "#D5DDE0"
const YELLOW = "#FFE000"
const GREY = "#636363"
const BLACKGREY = "#323C46"
const LIGHTGREY  = "#f5f5f5"
const GREYWHITE = "#F5F5F5"
const RED  = "#FF5252"
const MAINCOLOR = "#323C46"
const GREEN = "#15CF74"
const DARKGREEN = "#177256"
const DARKBLUE = "#082a4d"
const ORANGE = "#323C46"
const PURPLE = "#9C27B0"


const COLORS = {
    BLACK,
    WHITE,
    BORDERCOLOR,
    YELLOW,
    GREY,
    BLACKGREY,
    LIGHTGREY,
    GREYWHITE,
    RED,
    MAINCOLOR,
    GREEN,
    DARKGREEN,
    DARKBLUE,
    ORANGE,
    PURPLE
}

export default COLORS